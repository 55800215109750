import { all, put, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { FilterActions } from '../store/filter';
import { PayloadMetaAction } from 'typesafe-actions';
import { ApolloQueryResult } from '@apollo/client';
import { Filter } from '../declaration/graphql';
import { toast } from 'react-toastify';
import { replace } from 'connected-react-router';

export default function* rootSaga() {
  yield all([...createAsyncSaga(FilterActions), watchUpsertFilter()]);
}

function* watchUpsertFilter() {
  yield takeEvery(FilterActions.upsertFilter.success, function* (
    action: PayloadMetaAction<string, ApolloQueryResult<{ upsertFilter: Filter }>, [any]>
  ) {
    if (action.meta[0].id) {
      toast.success(`${action.payload.data.upsertFilter.name} 필터가 업데이트 되었습니다.`, { position: 'bottom-right' });
    } else {
      toast.success(`${action.payload.data.upsertFilter.name} 필터가 생성 되었습니다.`, { position: 'bottom-right' });
      yield put(replace(`/filters/${action.payload.data.upsertFilter.id}`));
    }
  });
}
