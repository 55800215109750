import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "./useQuery";
import { useHistory } from "react-router";

export function usePagination(): [number, (page: number) => void] {
  const query = useQuery();
  const history = useHistory();
  const queryPage = query.get('page');
  const integerQueryPage = useMemo(() => queryPage === null ? 1 : parseInt(queryPage), [queryPage]);

  useEffect(() => {
    if (queryPage === null) {
      query.set('page', (1).toString());
      history.replace(`?${query.toString()}`);
    }
  }, [queryPage, history]);

  const setPage = useCallback((page: number) => {
    const pageString = page.toString();

    if (pageString === query.get('page')) {
      return;
    }

    query.set('page', pageString);
    history.push(`?${query.toString()}`);
  }, [query]);

  return [integerQueryPage, setPage];
}