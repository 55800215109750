import React, { FC, memo, useEffect, useState } from 'react';
import styles from './Pagination.module.scss';
import ReactPaginate from "react-paginate";
import { pageSize } from "../../cores/pageSize";

interface Props {
  page: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<Props> = memo(({ page, pageCount, onPageChange }) => {
  return (
    <div className={styles.pagination}>
      <ReactPaginate
        key={page}
        containerClassName={styles.container}
        pageClassName={styles.page}
        nextClassName={styles.page}
        previousClassName={styles.page}
        activeClassName={styles.active}
        previousLabel="이전"
        nextLabel="다음"
        initialPage={page - 1}
        pageCount={pageCount / pageSize}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={({ selected }: { selected: number }) => {
          onPageChange(Math.max(selected + 1, 1));
        }}
      />
    </div>
  );
});

export default Pagination;