import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './HashTagPage.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { chain, get } from 'lodash';
import { HashTag as IHashTag } from '../../declaration/graphql';
import HashTag from '../../components/HashTag/HashTag';
import { HashtagActions, HashtagType } from '../../store/hashtag';
import { useDispatch } from 'react-redux';
import Typography from '../../components/Typography/Typography';
import TextInput from 'src/components/TextInput/TextInput';
import { toast } from 'react-toastify';

interface Props {}

const HashTagPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const [newHashTag, setNewHashTag] = useState('');
  const [isRequested, setRequested] = useState(false);
  const { hashTags, isLoading } = useTypedSelector(({ hashtag: { hashTags }, loading: { asyncMap } }) => {
    return {
      hashTags,
      isLoading: get(asyncMap, HashtagType.GET_HASHTAGS) > 0,
    };
  });

  useEffect(() => {
    if (hashTags.length > 0 || isLoading || isRequested) {
      return;
    }

    setRequested(true);
    dispatch(HashtagActions.getHashTags());
  }, [hashTags, isLoading, isRequested, dispatch]);

  const renderedSelectedHashTags = useMemo(() => {
    return chain(hashTags)
      .map((hashTag: IHashTag) => {
        return <HashTag key={hashTag.id} name={hashTag.name} />;
      })
      .value();
  }, [hashTags]);

  return (
    <div className={styles.hashTagPage}>
      <div className={styles.form}>
        <div>
          <Typography className={styles.title} variant="h2">
            해시태그
          </Typography>
        </div>
        <div className={styles.hashTags}>{renderedSelectedHashTags}</div>
        <div className={styles.input}>
          <TextInput
            placeholder="추가할 해시태그"
            value={newHashTag}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const value = newHashTag.trim();
                if (!value) {
                  toast.warn('내용을 입력해주세요.');
                  return;
                }

                const findHashTagIndex = hashTags.findIndex((hashTag) => hashTag.name === value);
                if (findHashTagIndex > -1) {
                  toast.warn('이미 존재하는 해시태그입니다.');
                  return;
                } else {
                  toast.success(`${value} 해시태그가 생성되었습니다.`);
                  setNewHashTag('');
                  dispatch(HashtagActions.upsertHashTag({ name: value }));
                }
              }
            }}
            onChange={(e) => {
              setNewHashTag(e.currentTarget.value);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default HashTagPage;
