import { all, put, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { PostActions } from '../store/post';
import { PayloadMetaAction } from 'typesafe-actions';
import { ApolloQueryResult } from '@apollo/client';
import { Post } from '../declaration/graphql';
import { toast } from 'react-toastify';
import { replace } from 'connected-react-router';

export default function* rootSaga() {
  yield all([...createAsyncSaga(PostActions), watchUpsertPost()]);
}

function* watchUpsertPost() {
  yield takeEvery(PostActions.upsertPost.success, function* (
    action: PayloadMetaAction<string, ApolloQueryResult<{ upsertPost: Post }>, [any]>
  ) {
    if (action.meta[0].id) {
      toast.success(`${action.payload.data.upsertPost.title} 글이 업데이트 되었습니다.`, { position: 'bottom-right' });
    } else {
      toast.success(`${action.payload.data.upsertPost.title} 글이 생성 되었습니다.`, { position: 'bottom-right' });
      yield put(replace(`/posts/${action.payload.data.upsertPost.id}`));
    }
  });
}
