import produce from 'immer';
import { handleActions } from 'redux-actions';
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { client } from "../cores/client";
import { ApolloQueryResult, gql } from "@apollo/client";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { action, PayloadAction } from "typesafe-actions";
import { User } from '../declaration/graphql';

export enum UserType {
  SET_LOGIN = '@user/SET_LOGIN',
  SET_LOGOUT = '@user/SET_LOGOUT',
  SET_TOKEN = '@user/SET_TOKEN',
  GET_ME = '@user/GET_ME',
}

export interface UserState {
  token: string | null;
  user: User | null;
}

export const UserActions = {
  setToken: (token: string) => action(UserType.SET_TOKEN, token),
  setLogout: () => action(UserType.SET_LOGOUT),
  getMe: createAsyncSagaAction(UserType.GET_ME, () => {
    return client.query({
      query: gql`
        query {
          getMe {
            grade
          }
        }
      `,
    })
  }),
  setLogin: createAsyncSagaAction(UserType.SET_LOGIN, (username: string, password: string) => {
    return client.query({
      query: gql`
        query ($username: String!, $password: String!) {
          login(username: $username, password: $password)
        }
      `,
      variables: {
        username,
        password,
      }
    })
  })
};

const initialState: UserState = {
  token: null,
  user: null,
};

export default handleActions<UserState, any>(
  {
    [UserType.SET_TOKEN]: (state, action: PayloadAction<string, string>) => {
      return produce(state, draft => {
        draft.token = action.payload;
      })
    },
    [UserType.SET_LOGOUT]: (state) => {
      return produce(state, draft => {
        draft.token = null;
      })
    },
    ...createAsyncSagaReducerMap(UserType.SET_LOGIN, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ login: string }>>) => {
        return produce(state, draft => {
          draft.token = action.payload.data.login
        })
      }
    }),
    ...createAsyncSagaReducerMap(UserType.GET_ME, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ getMe: User }>>) => {
        return produce(state, draft => {
          draft.user = action.payload.data.getMe
        })
      }
    })
  },
  initialState
);
