import produce from 'immer';
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './HashTagSelector.module.scss';
import { HashTag as IHashTag } from '../../declaration/graphql';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, find, findIndex, chain, size } from 'lodash';
import { HashtagActions, HashtagType } from '../../store/hashtag';
import { useDispatch } from 'react-redux';
import HashTag from '../HashTag/HashTag';
import Typography from '../Typography/Typography';
import { RiAddLine } from 'react-icons/ri';
import { Portal } from 'react-portal';
import ClickOut from '../ClickOut/ClickOut';
import { useScrollPosition } from '../../hooks/useScrollPosition';

interface Props {
  values: Array<IHashTag['id']>;
  onChange: (values: Array<IHashTag['id']>) => void;
}

const HashTagSelector: FC<Props> = memo(({ values, onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [isRequested, setRequested] = useState(false);
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });
  const { y: scrollY } = useScrollPosition();

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { hashTags, isLoading } = useTypedSelector(({ hashtag: { hashTags }, loading: { asyncMap } }) => {
    return {
      hashTags,
      isLoading: get(asyncMap, HashtagType.GET_HASHTAGS) > 0,
    };
  });

  useEffect(() => {
    if (hashTags.length > 0 || isLoading || isRequested) {
      return;
    }

    setRequested(true);
    dispatch(HashtagActions.getHashTags());
  }, [hashTags, isLoading, isRequested, dispatch]);

  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }

    const { left, top } = buttonRef.current.getBoundingClientRect();
    setPosition({ x: left + buttonRef.current.offsetWidth, y: Math.max(top - 300 + scrollY, 0) });
  }, [values, isOpen, scrollY]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  const renderedSelectedHashTags = useMemo(() => {
    return chain(values)
      .map((id) => find(hashTags, (hashTag) => hashTag.id === id))
      .filter()
      .map((hashTag: IHashTag) => {
        return (
          <HashTag
            key={hashTag.id}
            name={hashTag.name}
            onClick={() => {
              const hashTagIndex = findIndex(values, (value) => value === hashTag.id);

              if (hashTagIndex !== -1) {
                onChange(
                  produce(values, (draft) => {
                    draft.splice(hashTagIndex, 1);
                  })
                );
              }
            }}
          />
        );
      })
      .value();
  }, [values, hashTags]);

  const renderedLeftHashTags = useMemo(() => {
    let query = chain(hashTags);

    if (search !== '') {
      query = query.filter((hashTag) => hashTag.name.indexOf(search) !== -1);
    }

    return query
      .filter((hashTag) => findIndex(values, (value) => value === hashTag.id) === -1)
      .map((hashTag: IHashTag) => {
        return (
          <div
            className={styles.leftHashTag}
            key={hashTag.id}
            onClick={() => {
              onChange(
                produce(values, (draft) => {
                  draft.push(hashTag.id);
                })
              );
            }}
          >
            {hashTag.name}
          </div>
        );
      })
      .value();
  }, [values, hashTags, search]);

  return (
    <div className={styles.hashTagSelector}>
      <Typography className={styles.label} variant="p">
        해시태그
      </Typography>
      <div className={styles.selectedHashTags}>
        {size(renderedSelectedHashTags) <= 0 && !isLoading ? <span className={styles.empty}>아직 등록된 해시태그가 없습니다.</span> : renderedSelectedHashTags}
        <button
          ref={buttonRef}
          className={styles.addHashTagButton}
          type="button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <RiAddLine />
        </button>
      </div>
      {isOpen && (
        <Portal>
          <ClickOut
            onClickOut={() => {
              setOpen(false);
            }}
          >
            <div className={styles.popup} style={{ left: x, top: y }}>
              <div className={styles.search}>
                <input
                  ref={inputRef}
                  placeholder="검색"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={styles.leftHashTags}>{renderedLeftHashTags}</div>
            </div>
          </ClickOut>
        </Portal>
      )}
    </div>
  );
});

export default HashTagSelector;
