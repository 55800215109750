import { all, put, select, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { PayloadMetaAction } from 'typesafe-actions';
import { ApolloQueryResult } from '@apollo/client';
import { ContactInput, Contract, ContractInput } from '../declaration/graphql';
import { toast } from 'react-toastify';
import { replace } from 'connected-react-router';
import { ContractActions } from 'src/store/contract';
import axios from 'axios';
import { DateTime } from 'luxon';
import { RootState } from '../store';
import { get } from 'lodash';

axios.defaults.baseURL = process.env.REACT_APP_API_RESTFUL;

export default function* rootSaga() {
  yield all([...createAsyncSaga(ContractActions), watchUpsertContract(), watchDownloadContract(), watchDeleteContract()]);
}

function* watchDownloadContract() {
  yield takeEvery(ContractActions.downloadContract.success, function* (
    action: PayloadMetaAction<string, any, [ContractInput['id']]>
  ) {
    const id = action.meta[0];
    if (!id) {
      return;
    }

    const contractMap = yield select(({ contract: { contractMap } }: RootState) => contractMap);
    const contract = get(contractMap, id);
    const campaign = get(contract, 'campaign');
    const company = get(contract, 'company');

    const url = window.URL.createObjectURL(new Blob([action.payload.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${DateTime.local().toFormat('yyyyMMdd')}${campaign ? `_${campaign}` : ''}${company ? `_${company}` : ''}_계약서.docx`
    );
    document.body.appendChild(link);
    link.click();
  });
}

function* watchUpsertContract() {
  yield takeEvery(ContractActions.upsertContract.success, function* (
    action: PayloadMetaAction<string, ApolloQueryResult<{ upsertContract: Contract }>, [ContractInput, boolean]>
  ) {
    if (action.meta[1]) {
      yield put(ContractActions.downloadContract(action.meta[0].id));
    } else if (action.meta[0].id) {
      toast.success(`${action.payload.data.upsertContract.campaign} 계약서가 업데이트 되었습니다.`, { position: 'bottom-right' });
    } else {
      toast.success(`${action.payload.data.upsertContract.campaign} 계약서가 생성 되었습니다.`, { position: 'bottom-right' });
      yield put(replace(`/contracts/${action.payload.data.upsertContract.id}`));
    }
  });
}

function* watchDeleteContract() {
  yield takeEvery(ContractActions.deleteContract.success, function* (
    action: PayloadMetaAction<string, ApolloQueryResult<{ deleteContract: { id: Contract['id'] } }>, [string]>
  ) {
    toast.success(`계약서가 삭제 되었습니다.`, { position: 'bottom-right' });
    yield put(replace('/contracts/'));
  });
}
