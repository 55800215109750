import React, { FC, memo, ReactNode } from 'react';
import styles from './TableRow.module.scss';
import { Link } from "react-router-dom";
import classNames from 'classnames';

interface Props {
  to?: string;
  children: ReactNode;
  isHeader?: boolean;
}

const TableRow: FC<Props> = memo(({ to, children, isHeader } ) => {
  if (!to) {
    return (
      <div className={classNames(styles.tableRow, isHeader&&styles.isHeader)}>
        {children}
      </div>
    )
  }

  return (
    <Link to={to} className={styles.tableRow}>
      {children}
    </Link>
  );
});

export default TableRow;