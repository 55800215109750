import { all } from 'redux-saga/effects';
import user from "./user";
import post from './post';
import filter from "./filter";
import loading from "./loading";
import hashtag from "./hashtag";
import search from './search';
import contact from './contact';
import contract from './contract';

export default function* rootSaga() {
  yield all([
    user(),
    post(),
    filter(),
    search(),
    contact(),
    hashtag(),
    loading(),
    contract(),
  ]);
}
