import { all, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { SearchActions } from 'src/store/search';
import { PayloadMetaAction } from 'typesafe-actions';
import { ApolloQueryResult } from '@apollo/client';
import { Search } from 'src/declaration/graphql';
import { toast } from 'react-toastify';
import { getSearchNameByDisplay } from '../cores/getSearchNameByDisplay';

export default function* rootSaga() {
  yield all([...createAsyncSaga(SearchActions), watchUpdateSearch()]);
}

function* watchUpdateSearch() {
  yield takeEvery(SearchActions.updateSearch.success, function* (
    action: PayloadMetaAction<string, ApolloQueryResult<{ updateSearch: Search }>, [any]>
  ) {
    toast.success(`${getSearchNameByDisplay(action.payload.data.updateSearch.name)} 설정이 업데이트 되었습니다.`, {
      position: 'bottom-right',
    });
  });
}
