import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './DatePicker.module.scss';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { map } from 'lodash';
import Ink from 'react-ink';
import OutsideClickHandler from 'react-outside-click-handler';
import { Portal } from 'react-portal';
import { getPosition } from "../../cores/getPosition";
import Typography from "../Typography/Typography";


interface Props {
  label?: string;
  value: string | null;
  onChange: (date: string) => void;
  placeholder?: string;
  range?: { min: string | null; max: string | null };
  isModify?: boolean;
  isActivated?: boolean;
  isModifiable?: boolean;
}

export const todayDateTime = DateTime.fromJSDate(new Date()).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
});

const captions = ['일', '월', '화', '수', '목', '금', '토'];

interface ParsedRange {
  min: DateTime | null;
  max: DateTime | null;
}

function getRange(range: Props['range']): ParsedRange | null {
  if (!range) {
    return { min: null, max: null };
  }

  const result: Partial<ParsedRange> = {};

  if (range.min) {
    result.min = DateTime.fromFormat(range.min, 'yyyy-MM-dd HH:mm:ss');
  }

  if (range.max) {
    result.max = DateTime.fromFormat(range.max, 'yyyy-MM-dd HH:mm:ss');
  }

  return result as Required<ParsedRange>;
}

const DatePicker: FC<Props> = memo(({ value, placeholder, onChange, isModify, label, isActivated, isModifiable, range }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [addMonth, setAddMonth] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const parsedRange = getRange(range);
  const selectedDateTime = value
    ? DateTime.fromISO(value).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
    : null;

  const parsedDateTime = value
    ? DateTime.fromISO(value).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
    : DateTime.local().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });

  const onResize = useCallback(() => {
    if (inputRef.current) {
      const position = getPosition(inputRef.current);

      if (position === null) {
        return;
      }

      setPosition(position);

      if (popupRef.current) {
        const position = getPosition(inputRef.current);

        if (position === null) {
          return;
        }

        if (window.scrollY + window.innerHeight > position.y + 42 + popupRef.current.offsetHeight) {
          setPosition(position);
        } else {
          setPosition({ x: position.x, y: position.y - (popupRef.current.offsetHeight + 42) });
        }
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onResize);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', onResize);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const standardDateTime = parsedDateTime.plus({ month: addMonth });
  const days = [];
  const startDay = standardDateTime.set({ day: 1 }).weekday;
  const startIndex = startDay === 7 ? 1 : -(startDay - 1);
  const endIndex = standardDateTime.daysInMonth + (42 - (Math.abs(startIndex) + standardDateTime.daysInMonth)) - 1;
  const onClickDay = useCallback((dateTime: DateTime) => {
    onChange(dateTime.toISO());
  }, []);

  for (let i = startIndex; i <= endIndex; i++) {
    const dateTime = standardDateTime.set({ day: i });
    const value = dateTime.toFormat('yyyy-MM-dd');
    const isToday = dateTime.equals(todayDateTime);
    const isSelected = selectedDateTime !== null && dateTime.equals(selectedDateTime);
    const isSaturday = dateTime.weekday === 6;
    const isSunday = dateTime.weekday === 7;
    const isNotDayInMonths = dateTime.month !== standardDateTime.month;
    const isDisabled =
      parsedRange !== null &&
      ((parsedRange.min && parsedRange.min.diff(dateTime).milliseconds > 0) ||
        (parsedRange.max && parsedRange.max.diff(dateTime).milliseconds < 0));

    days.push(
      <div
        key={value}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          setAddMonth(0);
          onClickDay(dateTime);
          onClose();
        }}
        className={classNames(
          styles.day,
          isToday && styles.isToday,
          isSelected && styles.isSelected,
          isSaturday && styles.isSaturday,
          isSunday && styles.isSunday,
          isDisabled && styles.isDisabled,
          isNotDayInMonths && styles.isNotDayInMonths
        )}
      >
        <div className={styles.text}>{dateTime.day}</div>
        <Ink />
      </div>
    );
  }

  const decrement = useCallback(() => {
    setAddMonth(prevAddMonth => prevAddMonth - 1);
  }, []);

  const increment = useCallback(() => {
    setAddMonth(prevAddMonth => prevAddMonth + 1);
  }, []);

  const onClickInput = useCallback(() => {
    if (!isModifiable) {
      return;
    }

    setOpen(true);
  }, [isModifiable, todayDateTime, parsedRange]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    onResize();
  }, [open]);

  return (
    <div className={styles.calendar}>
      {label && (
        <Typography variant="p" className={styles.label}>
          {label}
        </Typography>
      )}
      <div ref={inputRef} className={styles.input} onClick={onClickInput}>
        {selectedDateTime ? (
          <p className={styles.value}>{selectedDateTime.toFormat('yyyy-MM-dd')}</p>
        ) : (
          <p className={styles.placeholder}>{placeholder}</p>
        )}
        {isModifiable && <div className={classNames(styles.icon, isModify && styles.isModify)}>{isModify && '수정'}</div>}
      </div>
      {open && (
        <Portal>
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={styles.popup} ref={popupRef} style={{ left: position.x, top: position.y + 42 }}>
              <div className={styles.header}>
                <button className={styles.decrement} onClick={decrement}>
                  <Ink />
                </button>
                <p className={styles.title}>
                  {standardDateTime.year} / {standardDateTime.month}
                </p>
                <button className={styles.increment} onClick={increment}>
                  <Ink />
                </button>
              </div>
              <div className={styles.body}>
                <div className={styles.captions}>
                  {map(captions, (caption, key) => {
                    const isLast = captions.length - 1 === key;
                    return (
                      <div key={caption} className={classNames(styles.caption, isLast && styles.isLast)}>
                        {caption}
                      </div>
                    );
                  })}
                </div>
                <div className={styles.days}>{days}</div>
              </div>
              <div className={styles.actions}>
              </div>
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
});

export default DatePicker;
