import React, { FC, memo } from 'react';
import styles from './Field.module.scss';

interface Props {
  label: string;
  value: string;
}

const Field: FC<Props> = memo(({ label, value }) => {
  return (
    <div className={styles.field}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
});

export default Field;
