import React, { FC, memo, useCallback } from 'react';
import styles from './ContactItemForm.module.scss';
import { Contact } from 'src/declaration/graphql';
import Field from 'src/components/Field/Field';
import { DateTime } from 'luxon';
import Button from 'src/components/Button/Button';
import { useDispatch } from 'react-redux';
import { ContactActions } from 'src/store/contact';
import AnimateHeight from 'react-animate-height';

interface Props {
  data: Contact;
  isDisabled: boolean;
}

const ContactItemForm: FC<Props> = memo(({ data, isDisabled }) => {
  const dispatch = useDispatch();
  const handleResolved = useCallback(() => {
    dispatch(ContactActions.updateContact({ id: data.id, resolved: true }));
  }, [data]);

  const handlePending = useCallback(() => {
    dispatch(ContactActions.updateContact({ id: data.id, resolved: false }));
  }, [data]);

  return (
    <AnimateHeight height={isDisabled ? 0 : 'auto'} duration={300}>
      <div className={styles.contactItemForm}>
        <Field
          label="이름 (회사명)"
          value={data.company !== null && data.company !== '' ? `${data.name} (${data.company})` : data.name}
        />
        <Field label="이메일" value={data.email} />
        <Field label="전화번호" value={data.phone_number} />
        <Field label="내용" value={data.content} />
        <div className={styles.horizontal}>
          {data.budget && <Field label="예산" value={data.budget.name} />}
          {data.type && <Field label="기간" value={data.type.name} />}
          {data.category && <Field label="카테고리" value={data.category.name} />}
          <Field label="문의일" value={DateTime.fromISO(data.created_at).toFormat('yyyy.MM.dd HH:mm:ss')} />
        </div>
        <div className={styles.actions}>
          {data.resolved ? <Button onClick={handlePending}>대기</Button> : <Button onClick={handleResolved}>완료</Button>}
        </div>
      </div>
    </AnimateHeight>
  );
});

export default ContactItemForm;
