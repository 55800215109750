import React, { FC, FormEvent } from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';
import { useFocus } from '../../hooks/useFocus';
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography/Typography";

interface Props {
  className?: string;
  icon?: React.ReactNode;
  type?: string;
  name?: string;
  value: string;
  label?: string;
  placeholder?: string;
  onChange: (e: FormEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<Props> = ({ className, placeholder = '', label = '', type = 'text', name, value, icon, onChange }) => {
  const [isFocus, onFocus, onBlur] = useFocus();
  const isDisplayPlaceholder = value === '' && placeholder !== '';

  return (
    <div className={classNames(styles.textArea, isFocus && styles.isFocus, icon && styles.hasIcon, className)}>
      {label && (
        <Typography variant="p" className={styles.label}>
          {label}
        </Typography>
      )}
      <div className={styles.input}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <CSSTransition in={isDisplayPlaceholder} timeout={300} classNames="" mountOnEnter unmountOnExit>
          <span className={styles.placeholder}>{placeholder}</span>
        </CSSTransition>
        <textarea name={name} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
      </div>
    </div>
  );
};

export default TextArea;