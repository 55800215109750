import React, { FC, memo, useEffect } from 'react';
import styles from './App.module.scss';
import { Route, Switch, useHistory } from 'react-router';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import LoginPage from '../../pages/LoginPage/LoginPage';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { routes } from '../../cores/routes';
import FiltersPage from '../../pages/FiltersPage/FiltersPage';
import FilterDetailPage from '../../pages/FilterDetailPage/FilterDetailPage';
import PostsPage from '../../pages/PostsPage/PostsPage';
import PostDetailPage from '../../pages/PostDetailPage/PostDetailPage';
import ConfigPage from '../../pages/ConfigPage/ConfigPage';
import HashTagPage from '../../pages/HashTagPage/HashTagPage';
import ContactsPage from '../../pages/ContactsPage/ContactsPage';
import ContractsPage from '../../pages/ContractsPage/ContractsPage';
import ContractDetailPage from '../../pages/ContractDetailPage/ContractDetailPage';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/user';

interface Props {}

const App: FC<Props> = memo(() => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, user } = useTypedSelector(({ user: { token, user } }) => ({ token, user }));

  useEffect(() => {
    if (!token) {
      return;
    }

    dispatch(UserActions.getMe());
  }, [token])

  useEffect(() => {
    if (!token) {
      return;
    }

    if (location.pathname === '/') {
      history.push(routes[0].pathname);
    }
  }, [token, location, history]);

  return (
    <div className={styles.app}>
      {token ? (
        <div>
          <div>
            <Sidebar user={user} />
          </div>
          <div className={styles.body}>
            <Switch>
              <Route exact path="/filters" component={FiltersPage} />
              <Route exact path="/filters/:id" component={FilterDetailPage} />
              <Route exact path="/posts" component={PostsPage} />
              <Route exact path="/posts/:id" component={PostDetailPage} />
              <Route exact path="/contacts" component={ContactsPage} />
              <Route exact path="/contracts" component={ContractsPage} />
              <Route exact path="/contracts/:id" component={ContractDetailPage} />
              <Route exact path="/hash_tags" component={HashTagPage} />
              <Route exact path="/config" component={ConfigPage} />
            </Switch>
          </div>
        </div>
      ) : (
        <LoginPage />
      )}
      <ToastContainer />
    </div>
  );
});

export default App;
