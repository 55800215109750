import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Filter, FilterInput, PaginationFilter } from "../declaration/graphql";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { client } from "../cores/client";
import { ApolloQueryResult, gql } from "@apollo/client";
import { pageSize } from "../cores/pageSize";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { PayloadMetaAction } from "typesafe-actions";

export enum FilterType {
  GET_FILTER_BY_ID = '@filter/GET_FILTER_BY_ID',
  GET_FILTERS = '@filter/GET_FILTERS',
  UPSERT_FILTER = '@filter/UPSERT_FILTER'
}

export interface FilterState {
  filterMap: { [key: number]: Filter };
  paginationFilterMap: { [key: number]: PaginationFilter };
}

export const FilterActions = {
  upsertFilter: createAsyncSagaAction(FilterType.UPSERT_FILTER, (data: FilterInput) => {
    return client.mutate({
      mutation: gql`
        mutation ($data: FilterInput!) {
          upsertFilter(data: $data) {
            id
            brand
            name
            author
            filter_url
            youtube_url
            thumbnail_url
            description
            made_by_us
            hash_tags {
              id
              name
            }
            priority
            featured
          }
        }
      `,
      variables: {
        data
      }
    })
  }),
  getFilterById: createAsyncSagaAction(FilterType.GET_FILTER_BY_ID, (id: number) => {
    return client.query({
      query: gql`
        query ($id: ID!) {
          getFilterById(id: $id) {
            id
            brand
            name
            author
            filter_url
            youtube_url
            thumbnail_url
            description
            made_by_us
            hash_tags {
              id
              name
            }
            priority
            featured
          }
        }
      `,
      variables: {
        id,
      }
    })
  }),
  getFilters: createAsyncSagaAction(FilterType.GET_FILTERS, (page: number = 1, search: string) => {
    return client.query({
      query: gql`
        query ($skip: Int!, $hashTagIds: [ID!]!, $search: String!) {
          getFilters(skip: $skip, hash_tag_ids: $hashTagIds, search: $search) {
            count
            results {
              id
              brand
              name
              priority
              made_by_us
              description
              created_at
            }
          }
        }
      `,
      variables: {
        skip: (page - 1) * pageSize,
        hashTagIds: [],
        search
      }
    })
  })
};

const initialState: FilterState = {
  filterMap: {},
  paginationFilterMap: {},
};

export default handleActions<FilterState, any>(
  {
    ...createAsyncSagaReducerMap(FilterType.UPSERT_FILTER, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ upsertFilter: Filter }>, [any]>) => {
        return produce(state, draft => {
          draft.filterMap[Number(action.payload.data.upsertFilter.id)] = action.payload.data.upsertFilter;
        })
      }
    }),
    ...createAsyncSagaReducerMap(FilterType.GET_FILTER_BY_ID, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getFilterById: Filter }>, [number]>) => {
        return produce(state, draft => {
          draft.filterMap[action.meta[0]] = action.payload.data.getFilterById;
        })
      }
    }),
    ...createAsyncSagaReducerMap(FilterType.GET_FILTERS, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getFilters: PaginationFilter }>, [number]>) => {
        return produce(state, draft => {
          draft.paginationFilterMap[action.meta[0]] = action.payload.data.getFilters;
        })
      }
    })
  },
  initialState
);
