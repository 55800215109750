import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import styles from './ContactsPage.module.scss';
import { useDispatch } from 'react-redux';
import { usePagination } from 'src/hooks/usePagination';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { get, map } from 'lodash';
import { Contact } from 'src/declaration/graphql';
import { usePrevious } from 'src/hooks/usePrevious';
import { ContactActions } from 'src/store/contact';
import Pagination from 'src/components/Pagination/Pagination';
import ContactItemForm from 'src/components/ContactItemForm/ContactItemForm';
import { useQuery } from 'src/hooks/useQuery';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from "../../components/Button/Button";

interface Props {}

const ContactsPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const [page, setPage] = usePagination();

  const query = useQuery();
  const resolved = query.get('resolved');

  const downloadContacts = useCallback(() => {
    dispatch(ContactActions.downloadContacts())
  }, []);

  const { paginationContact, disabledContacts, user } = useTypedSelector(({ contact: { paginationContactMap, disabledContacts }, user: { user } }) => {
    return {
      paginationContact: get(paginationContactMap, page),
      disabledContacts,
      user
    };
  });

  const grade = get(user, 'grade');
  const contacts: Array<Contact> = get(paginationContact, 'results', []);
  const pageCount: number = get(paginationContact, 'count');
  const prevPageCount = usePrevious(pageCount);

  useEffect(() => {
    dispatch(ContactActions.truncateContacts());
    dispatch(ContactActions.getContacts(page, !(resolved === null || resolved === 'false')));
  }, [page, resolved, dispatch]);

  return (
    <div className={styles.contactsPage}>
      <div className={styles.actions}>
        {grade >= 1 && <Button onClick={downloadContacts}>문의 다운로드</Button>}
      </div>
      <div className={styles.appBar}>
        <Link
          className={classNames(styles.menu, (resolved === null || resolved === 'false') && styles.isSelected)}
          to={{ pathname: '/contacts', search: new URLSearchParams({ page: '1', resolved: 'false' }).toString() }}
        >
          딥변대기
        </Link>
        <Link
          className={classNames(styles.menu, resolved === 'true' && styles.isSelected)}
          to={{ pathname: '/contacts', search: new URLSearchParams({ page: '1', resolved: 'true' }).toString() }}
        >
          답변완료
        </Link>
      </div>
      {map(contacts, (contact) => {
        return <ContactItemForm key={contact.id} data={contact} isDisabled={disabledContacts.indexOf(contact.id) > -1} />;
      })}
      <Pagination
        page={page}
        pageCount={pageCount || (typeof prevPageCount === 'number' ? prevPageCount : 0)}
        onPageChange={(page: number) => setPage(page)}
      />
    </div>
  );
});

export default ContactsPage;
