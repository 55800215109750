import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Search, SearchInput } from 'src/declaration/graphql';
import createAsyncSagaAction from 'src/cores/createAsyncSagaAction';
import { client } from 'src/cores/client';
import { ApolloQueryResult, gql } from '@apollo/client';
import { createAsyncSagaReducerMap } from 'src/cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';
import { findIndex } from 'lodash';

export enum SearchType {
  GET_SEARCHES = '@search/GET_SEARCHES',
  UPDATE_SEARCH = '@search/UPDATE_SEARCH',
}

export interface SearchState {
  searches: Array<Search>;
}

export const SearchActions = {
  updateSearch: createAsyncSagaAction(SearchType.UPDATE_SEARCH, (data: SearchInput) => {
    return client.mutate({
      mutation: gql`
        mutation ($data: SearchInput!) {
          updateSearch(data: $data) {
            id
            name
            hash_tags {
              id
              name
            }
          }
        }
      `,
      variables: {
        data
      }
    })
  }),
  getSearches: createAsyncSagaAction(SearchType.GET_SEARCHES, () => {
    return client.query({
      query: gql`
        query {
          getSearches {
            id
            name
            hash_tags {
              id
              name
            }
          }
        }
      `
    })
  })
};

const initialState: SearchState = {
  searches: [],
};

export default handleActions<SearchState, any>(
  {
    ...createAsyncSagaReducerMap(SearchType.UPDATE_SEARCH, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ updateSearch: Search }>>) => {
        const { id } = action.payload.data.updateSearch;
        const findSearchIndex = findIndex(state.searches, ((search) => search.id === id));
        if (findSearchIndex === -1) {
          return state;
        }

        return produce(state, draft => {
          draft.searches[findSearchIndex] = action.payload.data.updateSearch;
        })
      }
    }),
    ...createAsyncSagaReducerMap(SearchType.GET_SEARCHES, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ getSearches: Array<Search> }>>) => {
        return produce(state, draft => {
          draft.searches = action.payload.data.getSearches;
        })
      }
    })
  },
  initialState
);
