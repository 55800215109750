import React, { FC, memo, useRef } from 'react';
import styles from './ImageInput.module.scss';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import { createImage } from '../../cores/createImage';

interface Props {
  label: string;
  url: string;
  onChange: (url: string) => void;
}

const ImageInput: FC<Props> = memo(({ label, url, onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={styles.imageInput} onClick={() => {
      if (!inputRef.current) {
        return
      }

      inputRef.current.click();
    }}>
      <input
        ref={inputRef}
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            createImage(file)
              .then((payload) => {
                onChange(payload.data.createImage.url);
              })
              .catch((e) => {
                console.log(e.response);
              });
          }
        }}
        accept="image/png, image/jpeg"
      />
      {label && (
        <Typography variant="p" className={styles.label}>
          {label}
        </Typography>
      )}
      <div className={classNames(styles.image, !url && styles.isEmpty)} style={{ backgroundImage: url ? `url(${url})` : '' }} />
    </div>
  );
});

export default ImageInput;
