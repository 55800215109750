import React, { FC, memo } from 'react';
import styles from './CheckBox.module.scss';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import { RiCheckLine } from 'react-icons/ri';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const CheckBox: FC<Props> = memo(({ label, value, onChange }) => {
  return (
    <div
      className={classNames(styles.checkBox, value && styles.checked)}
      onClick={() => {
        onChange(!value);
      }}
    >
      <div className={styles.shape}>
        {value && <RiCheckLine className={styles.icon} />}
      </div>
      <Typography className={styles.label} variant="p">
        {label}
      </Typography>
    </div>
  );
});

export default CheckBox;
