import { all, put, take, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { get, size } from 'lodash';
import { toast } from 'react-toastify';
import { LoadingActions } from '../store/loading';
import { UserActions } from '../store/user';

function getPureType(type: string) {
  type = type.replace('/INITIAL', '').replace('/SUCCESS', '').replace('/FAILURE', '').replace('/CANCEL', '');
  return type;
}

export const ignoreLoadingTypes = [];

function* watchPendingAsyncActions() {
  yield takeEvery('*', function* (action: Action) {
    if (/\/INITIAL$/g.test(action.type)) {
      const type = getPureType(action.type);
      yield put(LoadingActions.setAsync(type, true));
    }
  });
}

function* watchEndAsyncActions() {
  while (true) {
    const action = yield take((action: Action) => {
      return /\/SUCCESS$/g.test(action.type) || /\/FAILURE$/g.test(action.type) || /\/CANCEL$/g.test(action.type);
    });

    const errors = get(action, 'payload.errors');

    if (action.payload instanceof Error || errors) {
      const graphQLErrors = get(action.payload, 'graphQLErrors') || errors;

      if (size(graphQLErrors) > 0) {
        for (let i = 0; i < graphQLErrors.length; i++) {
          const message = get(graphQLErrors[i], 'message');

          if (message === 'jwt-token-expired') {
            yield put(UserActions.setLogout());
            break;
          }

          toast.error(message, { position: 'bottom-right' });
        }
      }
    }

    const type = getPureType(action.type);

    yield put(LoadingActions.setAsync(type, false));
  }
}

export default function* rootSaga() {
  yield all([watchPendingAsyncActions(), watchEndAsyncActions()]);
}
