import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Post, PostInput, PaginationPost } from "../declaration/graphql";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { client } from "../cores/client";
import { ApolloQueryResult, gql } from "@apollo/client";
import { pageSize } from "../cores/pageSize";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { PayloadMetaAction } from "typesafe-actions";

export enum PostType {
  GET_POST_BY_ID = '@post/GET_POST_BY_ID',
  GET_POSTS_NOT_FILTERED = '@post/GET_POSTS_NOT_FILTERED',
  UPSERT_POST = '@post/UPSERT_POST'
}

export interface PostState {
  postMap: { [key: number]: Post };
  paginationPostMap: { [key: number]: PaginationPost };
}

export const PostActions = {
  upsertPost: createAsyncSagaAction(PostType.UPSERT_POST, (data: PostInput) => {
    return client.mutate({
      mutation: gql`
        mutation ($data: PostInput!) {
          upsertPost(data: $data) {
            id
            title
            content
            brunch_url
            external_url
            thumbnail_url
            priority
            published
            hash_tags {
              id
              name
            }
            created_at
          }
        }
      `,
      variables: {
        data
      }
    })
  }),
  getPostById: createAsyncSagaAction(PostType.GET_POST_BY_ID, (id: number) => {
    return client.query({
      query: gql`
        query ($id: ID!) {
          getPostById(id: $id) {
            id
            title
            content
            brunch_url
            external_url
            thumbnail_url
            priority
            published
            hash_tags {
              id
              name
            }
            created_at
          }
        }
      `,
      variables: {
        id,
      }
    })
  }),
  getPosts: createAsyncSagaAction(PostType.GET_POSTS_NOT_FILTERED, (page: number = 1) => {
    return client.query({
      query: gql`
        query ($skip: Int!) {
          getPostsNotFiltered(skip: $skip) {
            count
            results {
              id
              title
              priority
              published
              created_at
            }
          }
        }
      `,
      variables: {
        skip: (page - 1) * pageSize,
      }
    })
  })
};

const initialState: PostState = {
  postMap: {},
  paginationPostMap: {},
};

export default handleActions<PostState, any>(
  {
    ...createAsyncSagaReducerMap(PostType.UPSERT_POST, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ upsertPost: Post }>, [any]>) => {
        return produce(state, draft => {
          draft.postMap[Number(action.payload.data.upsertPost.id)] = action.payload.data.upsertPost;
        })
      }
    }),
    ...createAsyncSagaReducerMap(PostType.GET_POST_BY_ID, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getPostById: Post }>, [number]>) => {
        return produce(state, draft => {
          draft.postMap[action.meta[0]] = action.payload.data.getPostById;
        })
      }
    }),
    ...createAsyncSagaReducerMap(PostType.GET_POSTS_NOT_FILTERED, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getPostsNotFiltered: PaginationPost }>, [number]>) => {
        return produce(state, draft => {
          draft.paginationPostMap[action.meta[0]] = action.payload.data.getPostsNotFiltered;
        })
      }
    })
  },
  initialState
);
