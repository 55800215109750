import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './FilterDetailPage.module.scss';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { FilterActions } from '../../store/filter';
import { useFormik } from 'formik';
import TextInput from '../../components/TextInput/TextInput';
import TextArea from '../../components/TextArea/TextArea';
import HashTagSelector from '../../components/HashTagSelector/HashTagSelector';
import CheckBox from '../../components/CheckBox/CheckBox';
import Button from '../../components/Button/Button';
import ImageInput from '../../components/ImageInput/ImageInput';
import Typography from "../../components/Typography/Typography";

interface Props {}

const FilterDetailPage: FC<Props> = memo(() => {
  const params = useParams();
  const id = get(params, 'id');
  const { filter } = useTypedSelector(({ filter: { filterMap } }) => {
    return {
      filter: get(filterMap, id) || null,
    };
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const data = { ...values };
      if (data.priority === '') {
        data.priority = 0;
      } else {
        data.priority = Number(data.priority);
      }

      if (data.id === '') {
        delete data['id'];
      }

      dispatch(FilterActions.upsertFilter(data));
    },
    [dispatch]
  );

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      brand: '',
      name: '',
      author: '',
      filter_url: '',
      youtube_url: '',
      thumbnail_url: '',
      description: '',
      made_by_us: false,
      hash_tag_ids: [],
      priority: '1000000',
      featured: false,
    },
    onSubmit,
  });

  useEffect(() => {
    if (id === 'new') {
      return;
    }

    dispatch(FilterActions.getFilterById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!filter) {
      return;
    }

    setFieldValue('id', filter.id);
    setFieldValue('brand', filter.brand);
    setFieldValue('name', filter.name);
    setFieldValue('author', filter.author);
    setFieldValue('filter_url', filter.filter_url);
    setFieldValue('made_by_us', filter.made_by_us);
    setFieldValue('youtube_url', filter.youtube_url);
    setFieldValue('thumbnail_url', filter.thumbnail_url);
    setFieldValue('description', filter.description);
    if (filter.priority !== 0) {
      setFieldValue('priority', filter.priority);
    }
    setFieldValue('featured', filter.featured);
    setFieldValue(
      'hash_tag_ids',
      filter.hash_tags.map(({ id }) => id)
    );
  }, [filter, setFieldValue]);

  return (
    <div className={styles.filterDetailPage}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Typography className={styles.title} variant="h1">
          필터
        </Typography>
        <ImageInput
          label="썸네일"
          url={values.thumbnail_url}
          onChange={(url) => {
            setFieldValue('thumbnail_url', url);
          }}
        />
        <TextInput label="브랜드" name="brand" value={values.brand} onChange={handleChange} />
        <TextInput label="이름" name="name" value={values.name} onChange={handleChange} />
        <TextInput label="저자" name="author" value={values.author} onChange={handleChange} />
        <TextInput label="필터 주소" name="filter_url" value={values.filter_url} onChange={handleChange} />
        <TextInput label="유튜브 주소" name="youtube_url" value={values.youtube_url} onChange={handleChange} />
        <TextArea label="설명" name="description" value={values.description} onChange={handleChange} />
        <TextInput
          label="우선순위"
          name="priority"
          value={values.priority}
          onChange={(e) => {
            const value = e.currentTarget.value.replace(/\D/g, '');

            if (value !== '0') {
              setFieldValue('priority', value);
            }
          }}
        />
        <HashTagSelector
          values={values.hash_tag_ids}
          onChange={(values) => {
            setFieldValue('hash_tag_ids', values);
          }}
        />
        <CheckBox
          label="우리가 만든 필터"
          value={values.made_by_us}
          onChange={(value) => {
            setFieldValue('made_by_us', value);
          }}
        />
        <CheckBox
          label="홈 화면에 등록"
          value={values.featured}
          onChange={(value) => {
            setFieldValue('featured', value);
          }}
        />
        <div className={styles.actions}>
          <Button type="submit">저장하기</Button>
        </div>
      </form>
    </div>
  );
});

export default FilterDetailPage;
