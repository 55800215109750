import React, { FC, memo, useCallback } from 'react';
import styles from './LoginPage.module.scss';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { UserActions } from "../../store/user";
import { toast } from 'react-toastify';
import Typography from "../../components/Typography/Typography";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";

interface Props {
  
}

const LoginPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(({ username, password }: { username: string, password: string }) => {
    if (!username.trim()) {
      toast.warn('아이디를 입력해주세요.', { position: "bottom-right" });
      return
    }

    if (!username.trim()) {
      toast.warn('비밀번호를 입력해주세요.', { position: "bottom-right" });
      return
    }

    dispatch(UserActions.setLogin(username, password));
  }, [])

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit
  });

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Typography variant="h1">로그인</Typography>
          <TextInput className={styles.input} placeholder="아이디" name="username" value={values.username} onChange={handleChange} />
          <TextInput
            className={styles.input}
            placeholder="비밀번호"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          <div className={styles.actions}>
            <Button className={styles.loginButton} type="submit">로그인</Button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default LoginPage;