import React, { FC, memo } from 'react';
import styles from './HashTag.module.scss';

interface Props {
  name: string;
  onClick?: () => void;
}

const HashTag: FC<Props> = memo(({ name, onClick }) => {
  return (
    <div className={styles.hashTag} onClick={onClick}>
      {name}
    </div>
  );
});

export default HashTag;