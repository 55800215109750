import React, { FC, memo } from 'react';
import styles from './Sidebar.module.scss';
import { get, map } from 'lodash';
import { routes } from "../../cores/routes";
import { Link, useLocation } from "react-router-dom";
import classNames from 'classnames';
import { User } from '../../declaration/graphql';


interface Props {
  user: User | null;
}

const Sidebar: FC<Props> = memo(({ user }) => {
  const location = useLocation();
  const grade = get(user, 'grade');

  if (!user) {
    return null;
  }

  return (
    <div className={styles.sidebar}>
      {map(routes, (route, key) => {
        const isSelected = location.pathname.indexOf(route.pathname) !== -1;
        if (route.grade > grade) {
          return null;
        }

        return (
          <div key={key} className={classNames(styles.menu, isSelected && styles.isSelected)}>
            <Link to={route.pathname}>
              {route.name}
            </Link>
          </div>
        )
      })}
    </div>
  );
});

export default Sidebar;
