import { client } from "./client";
import { gql } from "@apollo/client";

export function createImage(file: File) {
  return client.mutate({
    mutation: gql`
      mutation ($file: Upload!) {
        createImage(file: $file) {
          id
          url
        }
      }
    `,
    variables: {
      file,
    },

  })
}