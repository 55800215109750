import React, { FC, memo, ReactNode } from 'react';
import styles from './TableData.module.scss';

interface Props {
  width?: string | number;
  align?: 'left' | 'right' |'center'
  children: ReactNode;
}

const TableData: FC<Props> = memo(({ width, align, children }) => {
  return (
    <div className={styles.tableData} style={{ width, justifyContent: align }}>{children}</div>
  );
});

export default TableData;