import React, { FC, memo, useEffect } from 'react';
import styles from './ContractsPage.module.scss';
import { usePagination } from "../../hooks/usePagination";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map } from 'lodash';
import { Contract } from '../../declaration/graphql';
import Pagination from "../../components/Pagination/Pagination";
import { usePrevious } from "../../hooks/usePrevious";
import Table from "../../components/Table/Table";
import TableRow from "../../components/TableRow/TableRow";
import TableData from "../../components/TableData/TableData";
import Typography from "../../components/Typography/Typography";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { ContractActions } from 'src/store/contract';
import { DateTime } from 'luxon';

interface Props {

}

const ContractsPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const [page, setPage] = usePagination();
  const paginationContract = useTypedSelector(({ contract: { paginationContractMap } }) => get(paginationContractMap, page));

  const contracts: Array<Contract> = get(paginationContract, 'results', []);
  const pageCount: number = get(paginationContract, 'count');
  const prevPageCount = usePrevious(pageCount);

  useEffect(() => {
    dispatch(ContractActions.getContracts(page));
  }, [page, dispatch]);

  return (
    <div className={styles.contractsPage}>
      <div className={styles.header}>
        <Typography className={styles.title} variant="h1">
          계약서 목록
        </Typography>
        <Link to="/contracts/new" className={styles.newContractButton}>
          새 계약서
          <Ink />
        </Link>
      </div>
      <Table>
        <TableRow isHeader>
          <TableData align="center" width="10%">-</TableData>
          <TableData width="60%">캠페인</TableData>
          <TableData width="10%">날짜</TableData>
        </TableRow>
        {map(contracts, ({ id, campaign, created_at }) => {
          return (
            <TableRow key={id} to={`/contracts/${id}`}>
              <TableData align="center" width="10%">{id}</TableData>
              <TableData width="60%">{campaign}</TableData>
              <TableData width="10%">{DateTime.fromISO(created_at).toFormat('yyyy-MM-dd')}</TableData>
            </TableRow>
          )
        })}
      </Table>
      <Pagination page={page} pageCount={pageCount || (typeof prevPageCount  === 'number' ? prevPageCount :  0)} onPageChange={(page: number) => setPage(page)} />
    </div>
  );
});

export default ContractsPage;
