import React, { FC, memo, useEffect } from 'react';
import styles from './PostsPage.module.scss';
import { useDispatch } from 'react-redux';
import { usePagination } from 'src/hooks/usePagination';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { get, map } from 'lodash';
import { Post } from 'src/declaration/graphql';
import { usePrevious } from 'src/hooks/usePrevious';
import Typography from 'src/components/Typography/Typography';
import { Link } from 'react-router-dom';
import Ink from 'react-ink';
import Table from 'src/components/Table/Table';
import TableRow from 'src/components/TableRow/TableRow';
import TableData from 'src/components/TableData/TableData';
import { DateTime } from 'luxon';
import Pagination from 'src/components/Pagination/Pagination';
import { PostActions } from 'src/store/post';
import { RiCheckLine } from "react-icons/ri";

interface Props {

}

const PostsPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const [page, setPage] = usePagination();
  const paginationPost = useTypedSelector(({ post: { paginationPostMap } }) => get(paginationPostMap, page));

  const posts: Array<Post> = get(paginationPost, 'results', []);
  const pageCount: number = get(paginationPost, 'count');
  const prevPageCount = usePrevious(pageCount);

  useEffect(() => {
    dispatch(PostActions.getPosts(page));
  }, [page, dispatch]);

  return (
    <div className={styles.postsPage}>
      <div className={styles.header}>
        <Typography className={styles.title} variant="h1">
          글 목록
        </Typography>
        <Link to="/posts/new" className={styles.newPostButton}>
          새 글
          <Ink />
        </Link>
      </div>
      <Table>
        <TableRow isHeader>
          <TableData align="center" width="10%">-</TableData>
          <TableData width="60%">제목</TableData>
          <TableData align="center" width="7.5%">우선순위</TableData>
          <TableData align="center" width="7.5%">게시여부</TableData>
          <TableData align="center" width="15%">날짜</TableData>
        </TableRow>
        {map(posts, ({ id, title, priority, published, created_at }) => {
          return (
            <TableRow key={id} to={`/posts/${id}`}>
              <TableData align="center" width="10%">{id}</TableData>
              <TableData width="60%">{title}</TableData>
              <TableData align="center" width="7.5%">{priority || ''}</TableData>
              <TableData align="center" width="7.5%">{published && <RiCheckLine />}</TableData>
              <TableData align="center" width="15%">{DateTime.fromISO(created_at).toFormat('yyyy-MM-dd HH:mm:ss')}</TableData>
            </TableRow>
          )
        })}
      </Table>
      <Pagination page={page} pageCount={pageCount || (typeof prevPageCount  === 'number' ? prevPageCount :  0)} onPageChange={(page: number) => setPage(page)} />
    </div>
  );
});

export default PostsPage;
