import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './ContractDetailPage.module.scss';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import Typography from '../../components/Typography/Typography';
import { ContractActions } from '../../store/contract';
import Select from '../../components/Select/Select';
import DatePicker from '../../components/DatePicker/DatePicker';
import { toLocaleString } from 'src/cores/toLocaleString';

interface Props {}

const contractTypes = [
  { name: '전액', value: 'A' },
  { name: '선금 + 잔금', value: 'B' },
  { name: '선금 + 중도금 + 잔금', value: 'C' },
];

function serializeValues(values: any) {
  const data = { ...values };
  const numericFields = [
    'first_price',
    'second_price',
    'third_price',
  ];

  const dateFields = [
    'first_payment_at',
    'first_payment_tax_invoice_published_at',
    'second_payment_at',
    'second_payment_tax_invoice_published_at',
    'third_payment_at',
    'third_payment_tax_invoice_published_at',
    'start_at',
    'end_at',
  ];

  if (data.id === '') {
    delete data['id'];
  }

  for (let i = 0; i < dateFields.length; i++) {
    const key = dateFields[i];
    const date = get(data, key);

    if (!date) {
      data[key] = null;
    }
  }

  if (data.type === 'A') {
    data.first_price = null;
    data.first_payment_at = null;
    data.first_payment_tax_invoice_published_at = null;
    data.second_price = null;
    data.second_payment_at = null;
    data.second_payment_tax_invoice_published_at = null;
  } else if (data.type === 'B') {
    data.second_price = null;
    data.second_payment_at = null;
    data.second_payment_tax_invoice_published_at = null;
  }

  let price = 0;
  for (let i = 0; i < numericFields.length; i++) {
    const key = numericFields[i];
    const numeric = get(data, key);

    if (numeric) {
      data[key] = Number(numeric);
      price += data[key];
    } else {
      data[key] = null;
    }
  }

  data.price = price;

  return data;
}

const ContractDetailPage: FC<Props> = memo(() => {
  const params = useParams();
  const id = get(params, 'id');
  const { contract } = useTypedSelector(({ contract: { contractMap } }) => {
    return {
      contract: get(contractMap, id) || null,
    };
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const data = serializeValues(values);
      dispatch(ContractActions.upsertContract(data));
    },
    [dispatch]
  );

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      type: '',
      name: '',
      first_price: '',
      second_price: '',
      third_price: '',
      price: '',
      campaign: '',
      company: '',
      address: '',
      corporate_registration_number: '',
      first_payment_at: '',
      first_payment_tax_invoice_published_at: '',
      second_payment_at: '',
      second_payment_tax_invoice_published_at: '',
      third_payment_at: '',
      third_payment_tax_invoice_published_at: '',
      start_at: '',
      end_at: '',
    },
    onSubmit,
  });

  const downloadContract = useCallback(() => {
    const data = serializeValues(values);
    dispatch(ContractActions.upsertContract(data, true));
  }, [values]);

  useEffect(() => {
    if (id === 'new') {
      return;
    }

    dispatch(ContractActions.getContractById(id));
  }, [id, dispatch]);

  const removeContract = useCallback(() => {
    if (id === 'new') {
      return;
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      dispatch(ContractActions.deleteContract(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!contract) {
      return;
    }

    setFieldValue('id', contract.id);
    setFieldValue('type', contract.type);
    setFieldValue('name', contract.name);
    setFieldValue('first_price', contract.first_price);
    setFieldValue('second_price', contract.second_price);
    setFieldValue('third_price', contract.third_price);
    setFieldValue('price', contract.price);
    setFieldValue('campaign', contract.campaign);
    setFieldValue('company', contract.company);
    setFieldValue('address', contract.address);
    setFieldValue('corporate_registration_number', contract.corporate_registration_number);
    setFieldValue('first_payment_at', contract.first_payment_at);
    setFieldValue('first_payment_tax_invoice_published_at', contract.first_payment_tax_invoice_published_at);
    setFieldValue('second_payment_at', contract.second_payment_at);
    setFieldValue('second_payment_tax_invoice_published_at', contract.second_payment_tax_invoice_published_at);
    setFieldValue('third_payment_at', contract.third_payment_at);
    setFieldValue('third_payment_tax_invoice_published_at', contract.third_payment_tax_invoice_published_at);
    setFieldValue('start_at', contract.start_at);
    setFieldValue('end_at', contract.end_at);
  }, [contract, setFieldValue]);

  const isA = values.type === 'A';
  const isB = values.type === 'B';
  const isC = values.type === 'C';

  const thirdDisplayName = isA ? '전액' : '잔금';

  return (
    <div className={styles.filterDetailPage}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Typography className={styles.title} variant="h2">
          {values.campaign} 계약서
        </Typography>
        <Select
          placeholder="계약서 종류"
          options={contractTypes}
          value={values.type}
          onChange={(value) => {
            setFieldValue('type', value);
          }}
        />
        <TextInput label="캠페인 명" name="campaign" value={values.campaign} onChange={handleChange} />
        <div>
          <DatePicker
            label="시작일"
            isModifiable
            value={values.start_at}
            onChange={(date) => {
              setFieldValue('start_at', date);
            }}
          />
          <DatePicker
            label="종료일"
            isModifiable
            value={values.end_at}
            onChange={(date) => {
              setFieldValue('end_at', date);
            }}
          />
        </div>
        <div className={styles.horizontal}>
          <TextInput label="대표이사" name="name" value={values.name} onChange={handleChange} />
          <TextInput label="회사 상호" name="company" value={values.company} onChange={handleChange} />
          <TextInput label="회사 주소" name="address" value={values.address} onChange={handleChange} />
          <TextInput
            label="사업자 등록번호"
            name="corporate_registration_number"
            value={values.corporate_registration_number}
            onChange={handleChange}
          />
          {(isB || isC) && (
            <div>
              <TextInput
                label="선급 금액"
                name="first_price"
                value={toLocaleString(values.first_price) || ''}
                onChange={(e) => {
                  setFieldValue('first_price', e.currentTarget.value.replace(/\D/g, ''));
                }}
              />
              <DatePicker
                label="선금 세금계산서 발행일"
                isModifiable
                value={values.first_payment_tax_invoice_published_at}
                onChange={(date) => {
                  setFieldValue('first_payment_tax_invoice_published_at', date);
                }}
              />
              <DatePicker
                label="선금 지급기일"
                isModifiable
                value={values.first_payment_at}
                onChange={(date) => {
                  setFieldValue('first_payment_at', date);
                }}
              />
            </div>
          )}

          {isC && (
            <div>
              <TextInput
                label="중도금 금액"
                name="second_price"
                value={toLocaleString(values.second_price) || ''}
                onChange={(e) => {
                  setFieldValue('second_price', e.currentTarget.value.replace(/\D/g, ''));
                }}
              />
              <DatePicker
                label="중도금 세금계산서 발행일"
                isModifiable
                value={values.second_payment_tax_invoice_published_at}
                onChange={(date) => {
                  setFieldValue('second_payment_tax_invoice_published_at', date);
                }}
              />
              <DatePicker
                label="중도금 지급기일"
                isModifiable
                value={values.second_payment_at}
                onChange={(date) => {
                  setFieldValue('second_payment_at', date);
                }}
              />
            </div>
          )}

          {values.type !== '' && (
            <div>
              <TextInput
                label={`${thirdDisplayName}`}
                name="third_price"
                value={toLocaleString(values.third_price) || ''}
                onChange={(e) => {
                  setFieldValue('third_price', e.currentTarget.value.replace(/\D/g, ''));
                }}
              />
              <DatePicker
                label={`${thirdDisplayName} 세금계산서 발행일`}
                isModifiable
                value={values.third_payment_tax_invoice_published_at}
                onChange={(date) => {
                  setFieldValue('third_payment_tax_invoice_published_at', date);
                }}
              />
              <DatePicker
                label={`${thirdDisplayName} 지급기일`}
                isModifiable
                value={values.third_payment_at}
                onChange={(date) => {
                  setFieldValue('third_payment_at', date);
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <div>
            {id !== 'new' && <Button variant="danger" type="button" onClick={removeContract}>삭제</Button>}
          </div>
          <div>
            <Button className={styles.downloadButton} type="button" onClick={downloadContract}>계약서 다운로드</Button>
            <Button type="submit">저장하기</Button>
          </div>
        </div>
      </form>
    </div>
  );
});

export default ContractDetailPage;
