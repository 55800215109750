import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './PostDetailPage.module.scss';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import TextInput from '../../components/TextInput/TextInput';
import TextArea from '../../components/TextArea/TextArea';
import HashTagSelector from '../../components/HashTagSelector/HashTagSelector';
import CheckBox from '../../components/CheckBox/CheckBox';
import Button from '../../components/Button/Button';
import ImageInput from '../../components/ImageInput/ImageInput';
import Typography from "../../components/Typography/Typography";
import { PostActions } from 'src/store/post';

interface Props {}

const PostDetailPage: FC<Props> = memo(() => {
  const params = useParams();
  const id = get(params, 'id');
  const { post } = useTypedSelector(({ post: { postMap } }) => {
    return {
      post: get(postMap, id) || null,
    };
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const data = { ...values };
      if (data.priority === '') {
        data.priority = 0;
      } else {
        data.priority = Number(data.priority);
      }

      if (data.id === '') {
        delete data.id;
      }

      if (data.title === '') {
        delete data.title;
      }

      if (data.thumbnail_url === '') {
        delete data.thumbnail_url;
      }

      delete data.content;

      dispatch(PostActions.upsertPost(data));
    },
    [dispatch]
  );

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      title: '',
      brunch_url: '',
      external_url: '',
      thumbnail_url: '',
      content: '',
      hash_tag_ids: [],
      priority: '1000000',
      published: false,
    },
    onSubmit,
  });

  useEffect(() => {
    if (id === 'new') {
      return;
    }

    dispatch(PostActions.getPostById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!post) {
      return;
    }

    setFieldValue('id', post.id);
    setFieldValue('title', post.title);
    setFieldValue('brunch_url', post.brunch_url);
    setFieldValue('external_url', post.external_url);
    setFieldValue('thumbnail_url', post.thumbnail_url);
    setFieldValue('content', post.content);
    if (post.priority !== 0) {
      setFieldValue('priority', post.priority);
    }
    setFieldValue('published', post.published);
    setFieldValue(
      'hash_tag_ids',
      post.hash_tags.map(({ id }) => id)
    );
  }, [post, setFieldValue]);

  return (
    <div className={styles.filterDetailPage}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Typography className={styles.title} variant="h1">
          글
        </Typography>
        <ImageInput
          label="썸네일"
          url={values.thumbnail_url}
          onChange={(url) => {
            setFieldValue('thumbnail_url', url);
          }}
        />
        <TextInput label="제목" name="title" value={values.title} onChange={handleChange} />
        <TextInput label="브런치 주소" name="brunch_url" value={values.brunch_url} onChange={handleChange} />
        <TextInput label="외부연결 주소" name="external_url" value={values.external_url} onChange={handleChange} />
        <TextArea label="내용" name="content" value={values.content} onChange={handleChange} />
        <TextInput
          label="우선순위"
          name="priority"
          value={values.priority}
          onChange={(e) => {
            const value = e.currentTarget.value.replace(/\D/g, '');

            if (value !== '0') {
              setFieldValue('priority', value);
            }
          }}
        />
        <HashTagSelector
          values={values.hash_tag_ids}
          onChange={(values) => {
            setFieldValue('hash_tag_ids', values);
          }}
        />
        <CheckBox
          label="게시 여부"
          value={values.published}
          onChange={(value) => {
            setFieldValue('published', value);
          }}
        />
        <div className={styles.actions}>
          <Button type="submit">저장하기</Button>
        </div>
      </form>
    </div>
  );
});

export default PostDetailPage;
