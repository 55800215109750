import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import user, { UserState } from "./user";
import post, { PostState } from './post';
import filter, { FilterState } from "./filter";
import search, { SearchState } from "./search";
import contact, { ContactState } from 'src/store/contact';
import hashtag, { HashtagState } from "./hashtag";
import loading, { LoadingState } from "./loading";
import contract, { ContractState } from 'src/store/contract';

export const history = createBrowserHistory();

export default combineReducers<RootState>({
  router: connectRouter(history),
  user,
  post,
  filter,
  search,
  contact,
  hashtag,
  loading,
  contract
});

export interface RootState {
  router: RouterState;
  user: UserState;
  post: PostState;
  filter: FilterState;
  search: SearchState;
  contact: ContactState;
  hashtag: HashtagState;
  loading: LoadingState;
  contract: ContractState;
}
