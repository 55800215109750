import React, { FC, memo, useEffect, useState } from 'react';
import styles from './FiltersPage.module.scss';
import { usePagination } from "../../hooks/usePagination";
import { useDispatch } from "react-redux";
import { FilterActions } from "../../store/filter";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { get, map } from 'lodash';
import { Filter } from "../../declaration/graphql";
import Pagination from "../../components/Pagination/Pagination";
import { usePrevious } from "../../hooks/usePrevious";
import Table from "../../components/Table/Table";
import TableRow from "../../components/TableRow/TableRow";
import TableData from "../../components/TableData/TableData";
import { DateTime } from "luxon";
import Typography from "../../components/Typography/Typography";
import { Link, useHistory } from "react-router-dom";
import Ink from "react-ink";
import { useQuery } from "../../hooks/useQuery";

interface Props {
  
}

const FiltersPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const query = useQuery();
  const search = query.get('search') || '';
  const history = useHistory();
  const [page, setPage] = usePagination();
  const [value, setValue] = useState(search);
  const paginationFilter = useTypedSelector(({ filter: { paginationFilterMap } }) => get(paginationFilterMap, page));

  const filters: Array<Filter> = get(paginationFilter, 'results', []);
  const pageCount: number = get(paginationFilter, 'count');
  const prevPageCount = usePrevious(pageCount);

  useEffect(() => {
    setValue(search);
  }, [search]);

  useEffect(() => {
    dispatch(FilterActions.getFilters(page, search));
  }, [page, search, dispatch]);

  return (
    <div className={styles.filtersPage}>
      <div className={styles.header}>
        <Typography className={styles.title} variant="h1">
          필터 목록
        </Typography>
        <Link to="/filters/new" className={styles.newFilterButton}>
          새 필터
          <Ink />
        </Link>
      </div>
      <div className={styles.actions}>
        <input
          placeholder="검색"
          value={value}
          onKeyDown={(e) => {
            const value = e.currentTarget.value;
            if (e.key === 'Enter') {
              if (value) {
                query.set('search', value);
              } else {
                query.delete('search');
              }

              query.set('page', '1');
              history.push(`?${query.toString()}`);
            }
          }}
          onChange={(e) => {
            setValue(e.currentTarget.value);
          }}
        />
      </div>
      <Table>
        <TableRow isHeader>
          <TableData align="center" width="10%">-</TableData>
          <TableData width="60%">이름</TableData>
          <TableData align="center" width="10%">우선순위</TableData>
          <TableData align="center" width="20%">날짜</TableData>
        </TableRow>
        {map(filters, ({ id, name, priority, created_at }) => {
          return (
            <TableRow key={id} to={`/filters/${id}`}>
              <TableData align="center" width="10%">{id}</TableData>
              <TableData width="60%">{name}</TableData>
              <TableData align="center" width="10%">{priority || ''}</TableData>
              <TableData align="center" width="20%">{DateTime.fromISO(created_at).toFormat('yyyy-MM-dd HH:mm:ss')}</TableData>
            </TableRow>
          )
        })}
      </Table>
      <Pagination page={page} pageCount={pageCount || (typeof prevPageCount  === 'number' ? prevPageCount :  0)} onPageChange={(page: number) => setPage(page)} />
    </div>
  );
});

export default FiltersPage;