import React, { FC, memo, useEffect } from 'react';
import styles from './ConfigPage.module.scss';
import { useDispatch } from 'react-redux';
import { SearchActions } from 'src/store/search';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { map } from 'lodash';
import SearchItemForm from '../../components/SearchItemForm/SearchItemForm';

interface Props {

}

const ConfigPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const { searches } = useTypedSelector(({ search: { searches } }) => {
    return {
      searches
    }
  });

  useEffect(() => {
    dispatch(SearchActions.getSearches());
  }, [dispatch]);

  return (
    <div className={styles.configPage}>
      {map(searches, search => {
        return (
          <SearchItemForm key={search.id} data={search} />
        )
      })}
    </div>
  );
});

export default ConfigPage;
