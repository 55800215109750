import produce from 'immer';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { handleActions } from 'redux-actions';
import { Contact, ContactUpdateInput, ContractInput, PaginationContact } from '../declaration/graphql';
import { client } from '../cores/client';
import { ApolloQueryResult, gql } from '@apollo/client';
import { pageSize } from '../cores/pageSize';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { action, PayloadMetaAction } from 'typesafe-actions';
import axios from "axios";

export enum ContactType {
  GET_CONTACTS = '@contact/GET_CONTACTS',
  UPDATE_CONTACT = '@contact/UPDATE_CONTACT',
  TRUNCATE_CONTACTS = '@contact/TRUNCATE_CONTACTS',
  DOWNLOAD_CONTACTS = '@contact/DOWNLOAD_CONTACTS',
}

export interface ContactState {
  disabledContacts: Array<Contact['id']>;
  paginationContactMap: { [key: number]: PaginationContact };
}

export const ContactActions = {
  truncateContacts: () => action(ContactType.TRUNCATE_CONTACTS),
  downloadContacts: createAsyncSagaAction(ContactType.DOWNLOAD_CONTACTS, () => {
    return axios.request({
      method: 'GET',
      url: `/contacts/download`,
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    });
  }),
  updateContact: createAsyncSagaAction(ContactType.UPDATE_CONTACT, (data: ContactUpdateInput) => {
    return client.mutate({
      mutation: gql`
        mutation($data: ContactUpdateInput!) {
          updateContact(data: $data) {
            id
          }
        }
      `,
      variables: {
        data,
      },
    });
  }),
  getContacts: createAsyncSagaAction(ContactType.GET_CONTACTS, (page: number = 1, resolved: boolean) => {
    return client.query({
      query: gql`
        query($skip: Int!, $resolved: Boolean!) {
          getContacts(skip: $skip, resolved: $resolved) {
            count
            results {
              id
              name
              email
              phone_number
              category {
                id
                name
              }
              budget {
                id
                name
              }
              type {
                id
                name
              }
              resolved
              company
              content
              created_at
            }
          }
        }
      `,
      variables: {
        skip: (page - 1) * pageSize,
        resolved,
      },
    });
  }),
};

const initialState: ContactState = {
  disabledContacts: [],
  paginationContactMap: {},
};

export default handleActions<ContactState, any>(
  {
    [ContactType.TRUNCATE_CONTACTS]: (state, action) => {
      return produce(state, draft => {
        draft.disabledContacts = [];
        draft.paginationContactMap = {};
      })
    },
    ...createAsyncSagaReducerMap(ContactType.UPDATE_CONTACT, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ updateContact: Contact }>, [number]>) => {
        return produce(state, (draft) => {
          draft.disabledContacts.push(action.payload.data.updateContact.id);
        });
      },
    }),
    ...createAsyncSagaReducerMap(ContactType.GET_CONTACTS, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getContacts: PaginationContact }>, [number]>) => {
        return produce(state, (draft) => {
          draft.disabledContacts = [];
          draft.paginationContactMap[action.meta[0]] = action.payload.data.getContacts;
        });
      },
    }),
  },
  initialState
);
