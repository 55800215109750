import { all, put, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from "../cores/createAsyncSaga";
import { UserActions, UserType } from "../store/user";
import { PayloadAction } from "typesafe-actions";
import { ApolloQueryResult } from "@apollo/client";

export default function* rootSaga() {
  yield all([
    ...createAsyncSaga(UserActions),
    getLocalStorageToken(),
    watchLogout(),
    watchLoginSuccess()
  ])
}

function* getLocalStorageToken() {
  const token = localStorage.getItem('token');
  if (token) {
    yield put(UserActions.setToken(token));
  }
}

function* watchLogout() {
  yield takeEvery(UserType.SET_LOGOUT, function* () {
    localStorage.removeItem('token');
  });
}

function* watchLoginSuccess() {
  yield takeEvery(UserActions.setLogin.success, function* (action: PayloadAction<string, ApolloQueryResult<{ login: string }>>) {
    localStorage.setItem('token', action.payload.data.login);
  });
}