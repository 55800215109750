import { useCallback, useEffect, useState } from 'react';

export function useScrollPosition(): { x: number; y: number } {
  const [position, setPosition] = useState({ x: window.scrollX, y: window.scrollY });

  const onScroll = useCallback(() => {
    setPosition({ x: window.scrollX, y: window.scrollY });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return position;
}
