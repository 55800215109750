import produce from 'immer';
import { handleActions } from 'redux-actions';
import { HashTag, HashTagInput } from '../declaration/graphql';
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { client } from "../cores/client";
import { ApolloQueryResult, gql } from "@apollo/client";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { PayloadAction } from "typesafe-actions";

export enum HashtagType {
  GET_HASHTAGS = '@hashtag/GET_HASHTAGS',
  UPSERT_HASHTAG = '@hashtag/UPSERT_HASHTAG'
}

export interface HashtagState {
  hashTags: Array<HashTag>;
}

export const HashtagActions = {
  upsertHashTag: createAsyncSagaAction(HashtagType.UPSERT_HASHTAG, (data: HashTagInput) => {
    return client.mutate({
      mutation: gql`
        mutation ($data: HashTagInput!) {
          upsertHashTag(data: $data) {
            id
            name
          }
        }      
      `,
      variables: {
        data
      }
    })
  }),
  getHashTags: createAsyncSagaAction(HashtagType.GET_HASHTAGS, () => {
    return client.query({
      query: gql`
        query {
          getHashTags {
            id
            name
          }
        }
      `,
    })
  })
};

const initialState: HashtagState = {
  hashTags: [],
};

export default handleActions<HashtagState, any>(
  {
    ...createAsyncSagaReducerMap(HashtagType.UPSERT_HASHTAG, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ upsertHashTag: HashTag }>>) => {
        return produce(state, draft => {
          draft.hashTags.push(action.payload.data.upsertHashTag);
        })
      }
    }),
    ...createAsyncSagaReducerMap(HashtagType.GET_HASHTAGS, {
      onSuccess: (state, action: PayloadAction<string, ApolloQueryResult<{ getHashTags: Array<HashTag> }>>) => {
        return produce(state, draft => {
          draft.hashTags = action.payload.data.getHashTags;
        })
      }
    })
  },
  initialState
);
