import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './SearchItemForm.module.scss';
import { Search } from 'src/declaration/graphql';
import { useFormik } from 'formik';
import HashTagSelector from 'src/components/HashTagSelector/HashTagSelector';
import Typography from 'src/components/Typography/Typography';
import Button from 'src/components/Button/Button';
import { useDispatch } from 'react-redux';
import { SearchActions } from 'src/store/search';
import { getSearchNameByDisplay } from '../../cores/getSearchNameByDisplay';

interface Props {
  data: Search;
}

const SearchItemForm: FC<Props> = memo(({ data }) => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values) => {
      dispatch(SearchActions.updateSearch(values));
    },
    [dispatch]
  );

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      hash_tag_ids: [],
    },
    onSubmit,
  });

  useEffect(() => {
    setFieldValue('id', data.id);
    setFieldValue(
      'hash_tag_ids',
      data.hash_tags.map(({ id }) => id)
    );
  }, [data]);

  return (
    <div className={styles.searchItemForm}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h2">{getSearchNameByDisplay(data.name)}</Typography>
        <HashTagSelector
          values={values.hash_tag_ids}
          onChange={(values) => {
            setFieldValue('hash_tag_ids', values);
          }}
        />
        <div className={styles.actions}>
          <Button type="submit">저장</Button>
        </div>
      </form>
    </div>
  );
});

export default SearchItemForm;
