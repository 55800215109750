import React, { FC, memo, ReactNode } from 'react';
import styles from './Table.module.scss';

interface Props {
  children: ReactNode;
}

const Table: FC<Props> = memo(({ children }) => {
  return (
    <div className={styles.table}>
      {children}
    </div>
  );
});

export default Table;