import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from "apollo-upload-client";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        authorization: token ? `JWT ${token}` : null
      }
    }
  });

  return forward(operation);
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API,
});


export const client = new ApolloClient({
  link: from([
    authLink,
    uploadLink,
  ]),
  cache: new InMemoryCache({
    resultCaching: false,
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
