import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Contract, ContractInput, PaginationContract } from '../declaration/graphql';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { client } from '../cores/client';
import { ApolloQueryResult, gql } from '@apollo/client';
import { pageSize } from '../cores/pageSize';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadMetaAction } from 'typesafe-actions';
import axios from 'axios';

export enum ContractType {
  GET_CONTRACT_BY_ID = '@contract/GET_CONTRACT_BY_ID',
  GET_CONTRACT = '@contract/GET_CONTRACT',
  DELETE_CONTRACT = '@contract/DELETE_CONTRACT',
  UPSERT_CONTRACT = '@contract/UPSERT_CONTRACT',
  DOWNLOAD_CONTRACT = '@contract/DOWNLOAD_CONTRACT',
}

export interface ContractState {
  contractMap: { [key: number]: Contract };
  paginationContractMap: { [key: number]: PaginationContract };
}

export const ContractActions = {
  downloadContract: createAsyncSagaAction(ContractType.DOWNLOAD_CONTRACT, (id: ContractInput['id']) => {
    return axios.request({
      method: 'GET',
      url: `/contracts/${id}/download`,
      responseType: 'blob',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    });
  }),
  upsertContract: createAsyncSagaAction(ContractType.UPSERT_CONTRACT, (data: ContractInput, download: boolean = false) => {
    return client.mutate({
      mutation: gql`
        mutation($data: ContractInput!) {
          upsertContract(data: $data) {
            id
            type
            name
            first_price
            second_price
            third_price
            price
            campaign
            company
            address
            corporate_registration_number
            first_payment_at
            first_payment_tax_invoice_published_at
            second_payment_at
            second_payment_tax_invoice_published_at
            third_payment_at
            third_payment_tax_invoice_published_at
            start_at
            end_at
            created_at
          }
        }
      `,
      variables: {
        data,
      },
    });
  }),
  getContractById: createAsyncSagaAction(ContractType.GET_CONTRACT_BY_ID, (id: number) => {
    return client.query({
      query: gql`
        query($id: ID!) {
          getContractById(id: $id) {
            id
            type
            name
            first_price
            second_price
            third_price
            price
            campaign
            company
            address
            corporate_registration_number
            first_payment_at
            first_payment_tax_invoice_published_at
            second_payment_at
            second_payment_tax_invoice_published_at
            third_payment_at
            third_payment_tax_invoice_published_at
            start_at
            end_at
            created_at
          }
        }
      `,
      variables: {
        id,
      },
    });
  }),
  getContracts: createAsyncSagaAction(ContractType.GET_CONTRACT, (page: number = 1) => {
    return client.query({
      query: gql`
        query($skip: Int!) {
          getContracts(skip: $skip) {
            count
            results {
              id
              type
              name
              company
              campaign
              start_at
              end_at
              created_at
            }
          }
        }
      `,
      variables: {
        skip: (page - 1) * pageSize,
      },
    });
  }),
  deleteContract: createAsyncSagaAction(ContractType.DELETE_CONTRACT, (id: Contract['id']) => {
    return client.query({
      query: gql`
        mutation($id: ID!) {
          deleteContract(id: $id)
        }
      `,
      variables: {
        id,
      },
    });
  }),
};

const initialState: ContractState = {
  contractMap: {},
  paginationContractMap: {},
};

export default handleActions<ContractState, any>(
  {
    ...createAsyncSagaReducerMap(ContractType.UPSERT_CONTRACT, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ upsertContract: Contract }>, [any]>) => {
        return produce(state, (draft) => {
          draft.contractMap[Number(action.payload.data.upsertContract.id)] = action.payload.data.upsertContract;
        });
      },
    }),
    ...createAsyncSagaReducerMap(ContractType.GET_CONTRACT_BY_ID, {
      onSuccess: (state, action: PayloadMetaAction<string, ApolloQueryResult<{ getContractById: Contract }>, [number]>) => {
        return produce(state, (draft) => {
          draft.contractMap[action.meta[0]] = action.payload.data.getContractById;
        });
      },
    }),
    ...createAsyncSagaReducerMap(ContractType.GET_CONTRACT, {
      onSuccess: (
        state,
        action: PayloadMetaAction<string, ApolloQueryResult<{ getContracts: PaginationContract }>, [number]>
      ) => {
        return produce(state, (draft) => {
          draft.paginationContractMap[action.meta[0]] = action.payload.data.getContracts;
        });
      },
    }),
  },
  initialState
);
