import { all, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { ContactActions } from 'src/store/contact';
import { PayloadAction } from "typesafe-actions";
import { DateTime } from "luxon";
import fileDownload from 'js-file-download';

export default function* rootSaga() {
  yield all([...createAsyncSaga(ContactActions), watchDownloadContacts()]);
}

function* watchDownloadContacts() {
  yield takeEvery(ContactActions.downloadContacts.success, function* (
    action: PayloadAction<string, any>
  ) {
    fileDownload("\ufeff" + action.payload.data, `${DateTime.local().toFormat('yyyyMMdd')}_문의.csv`)
  });
}