import React, { FC, FormEvent } from 'react';
import styles from './TextInput.module.scss';
import classNames from 'classnames';
import { useFocus } from '../../hooks/useFocus';
import { CSSTransition } from 'react-transition-group';
import Typography from '../Typography/Typography';

interface Props {
  className?: string;
  icon?: React.ReactNode;
  type?: string;
  name?: string;
  value: string;
  label?: string;
  placeholder?: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const TextInput: FC<Props> = ({
  className,
  placeholder = '',
  label = '',
  type = 'text',
  name,
  value,
  icon,
  onChange,
  onKeyDown,
}) => {
  const [isFocus, onFocus, onBlur] = useFocus();
  const isDisplayPlaceholder = value === '' && placeholder !== '';

  return (
    <div className={classNames(styles.textInput, isFocus && styles.isFocus, icon && styles.hasIcon, className)}>
      {label && (
        <Typography variant="p" className={styles.label}>
          {label}
        </Typography>
      )}
      <div className={styles.input}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <CSSTransition in={isDisplayPlaceholder} timeout={300} classNames="" mountOnEnter unmountOnExit>
          <span className={styles.placeholder}>{placeholder}</span>
        </CSSTransition>
        <input type={type} name={name} value={value} onChange={onChange} onKeyDown={onKeyDown} onFocus={onFocus} onBlur={onBlur} />
      </div>
    </div>
  );
};

export default TextInput;
